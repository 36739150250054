<template>
  <div :style="category.icon ? 'background:white': ''">
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>



      <div v-if="category.icon" style="width: 1170px; margin: 0 auto;padding-bottom: 50px; padding-top: 50px;">
        <div style="">
          <HelpNavBar :contentData="navBarData" :showButton="true" />
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 50px;" >
          <img style="height: 250px" :src="category.icon">
          <div style="max-width: 690px;">
            <h1 style="color: #2d3640;font-weight: bold; font-size: 46px;">{{category.title}}</h1>
            <p style="color: #5e6b79; line-height: 1.42; font-size: 24px;margin-top: 10px;">{{category.text}}</p>
          </div>
        </div>


        <div style="width: 1194px; margin:100px 0  0 50px; text-align: center;  ">
          <div style="display: flex; flex-wrap: wrap;">
            <div  v-if="sub_category.id !== 41 && sub_category.id !== 42" v-for="(sub_category, subi) in category.sub_categories" style=" width: 295px; justify-items: flex-start; text-align: left; display: flex; flex-direction: column;">
              <router-link :to="'/destek/kategori/'+sub_category.slug" class="useful-content-title">{{sub_category.title}}</router-link>
              <div
                :class="subi < 4 ? 'useful-content-detail':'useful-content-detail'"
                v-for="(article, arti) in sub_category.articles.slice(0,4)"
                :key="arti"
              >
                <router-link :to="'/destek/'+article.slug" v-if="arti < 4">{{article.title}}</router-link>
              </div>
              <router-link :to="'/destek/kategori/'+sub_category.slug" class="useful-content-show-more" v-if="sub_category.articles.length > 4">[Daha Fazla]</router-link>
            </div>
          </div>
        </div>



        <Faqs v-if="faqData.accordionData && faqData.accordionData.length > 0" :contentData="faqData"
              :cat-id="category.parent_id ? null : category.id"
              :sub-cat-id="category.parent_id ? category.id : null"
              :impression-u-u-i-d="impressionUUID"

        />

        <div style="display: flex;flex-direction: column;align-items: center;">
          <div style="display:flex; justify-content:center; align-items:center; margin-top:90px; flex-direction:column;">
            <div class="inner-headers">Yararlı bulabileceğin konu başlıkları</div>
            <div class="inner-header-description">En çok sorduğun konuları bir araya topladık. 😇</div>
          </div>
          <div style="min-width:85%;display:flex;justify-content:space-between;flex-direction:row;align-self:center;margin-top:50px">
            <div v-for="(other_category,othi) in other_categories" :key="othi" style="flex:1; display:flex; flex-direction:column; ">
              <img onload="SVGInject(this)" :src="other_category.icon" class="big-icon"/>
              <div style="display: flex;flex-direction: column;">
                <router-link :to="'/destek/kategori/'+other_category.slug" class="useful-content-title">{{other_category.title}}</router-link>
                <div
                  :class="othi < 4 ? 'useful-content-detail':''"
                  v-for="(other_sub_category, othersubi) in other_category.sub_categories"
                  :key="othersubi"
                >
                  <router-link :to="'/destek/kategori/'+other_sub_category.slug" v-if="othi < 4">{{other_sub_category.title}}</router-link>
                </div>
                <router-link :to="'/destek/kategori/'+other_category.slug" class="useful-content-show-more" v-if="other_category.sub_categories.length > 4">[Daha Fazla]</router-link>
              </div>
            </div>
          </div>

          <div class="new-ticket-bar-container">
            <div class="support-icon-container">
              <img
                src="https://gcdn.bionluk.com/site/cicons/ic-support.svg"
                onload="SVGInject(this)"
                class="support-icon"
              />
            </div>
            <p class="new-ticket-bar-text">
              Yardım istediğin konuyu destek makalelerimiz arasında bulamadın mı? O zaman destek talebi oluşturabilir ve taleplerini
              <router-link to="tickets"
                           style="font-weight:bold; color: white!important;"
              >Destek Taleplerim</router-link> bölümünden takip edebilirsin.
            </p>
            <router-link to="tickets?tab=new" class="new-ticket-button">
              <span  class="new-ticket-button-text">Destek Talebi Oluştur</span>
            </router-link>
          </div>
        </div>





      </div>


      <div v-else class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">


        <div style="display:flex;flex-direction:row; margin: 0 auto; width: 1170px;">
          <div style="display:flex;flex-direction:column; align-items:flex-end;  background-color:#ffffff; width: 230px;min-width: 230px; padding-bottom: 50px; ">
            <div style="display:flex;flex-direction:column;  margin-top:42px; ">
              <template  v-if="pageLoaded || category.parent_id">
                <p v-if="category.parent_id" style="margin-bottom:15px;font-size: 14px;font-weight: 600;color: #2d3640; text-transform: uppercase">{{category.parent_title}}</p>
                <p v-else style="margin-bottom:15px;font-size: 14px;font-weight: 600;color: #2d3640;">DESTEK KATEGORİLERİ</p>
              </template>
              <template v-if="category.parent_id">
                <router-link
                  v-if="item.id !== 41 && item.id !== 42"
                  :to="'/destek/kategori/'+item.slug"
                  v-for="(item,index) in main_categories.find(cat => cat.id === category.parent_id).sub_categories"
                  :key="index"
                  :class="item.id === category.id ? 'selected-category-row selected-category-item' : 'category-item'"
                  style="width:180px;padding-top:10px;padding-bottom:10px;margin-bottom:10px; cursor:pointer;"
                >{{item.title}}</router-link>
              </template>
              <template v-if="!category.parent_id">
                <router-link
                  :to="'/destek/kategori/'+item.slug"
                  v-for="(item,index) in main_categories"
                  :key="index"
                  :class="item.id === category.id ? 'selected-category-row selected-category-item' : 'category-item'"
                  style="width:180px;padding-top:10px;padding-bottom:10px;margin-bottom:10px; cursor:pointer;"
                >{{item.title}}</router-link>
              </template>
            </div>
          </div>


          <div v-if="!pageLoaded" style="width: 100%; margin-left:60px;  background-color: #f4f5f7;padding-bottom:70px; min-height:400px;">
            <div class="loader" style="text-align: center;"></div>
          </div>
          <div v-else style="width: 100%; margin-left:60px; margin-top: 40px;  background-color: #f4f5f7;padding-bottom:70px; min-height:400px;">

            <div style="">
              <HelpNavBar :contentData="navBarData" :showButton="true" />
            </div>

            <div class="other-category">
              <p class="main-title">{{category.title}}</p>
              <p class="main-sub-title">{{category.text}}</p>
              <div v-if="!category.parent_id"  class="sub-categories">
               <p class="sub-category" v-for="item in category.sub_categories"><router-link :to="'/destek/kategori/'+item.slug">{{item.title}}</router-link></p>
              </div>
              <div v-else class="sub-categories" >
                <p class="sub-category" v-for="item in category.articles"><router-link :to="'/destek/'+item.slug">{{item.title}}</router-link></p>
              </div>
            </div>


          </div>


        </div>
      </div>
  </div>
</template>

<script>

  import HelpNavBar from "../../../../components/HelpNavBar";
  import Faqs from "../../../../components/Faqs";

export default {
  name: "src-pages-body-staticContents-support-categoryList-v1",
  components: { HelpNavBar, Faqs },
  data() {
    return {

      impressionUUID:null,
      slug:null,
      pageLoaded:false,
      category:{
        id:null,
        parent_id:null,
        sub_categories:[],
        articles:[]
      },
      other_categories:[],
      main_categories:[],

      faqData: {
        title: " konusunda merak edilen sorular?",
        text: "En çok sorduğun konuları bir araya topladık. 😇",
        accordionData: []
      },
    };
  },
  methods: {

    getTopics(){

      this.impressionUUID = this.Helper.generateUUID();
      this.pageLoaded = false;
      this.api.content
        .retrieveSupportTopicsv2(this.slug)
        .then(({ data }) => {
          let result = data;
          if (result.success) {

            this.$store.state.indexHTMLTitle = result.data.meta.title;
            //this.$store.state.indexHTMLMetaDescription = result.data.meta.description;


            this.category = result.data.category;
            this.other_categories = result.data.other_categories;
            this.main_categories = result.data.main_categories;
            this.faqData.accordionData = result.data.faqs;
            this.faqData.title = this.category.title + ' konusunda merak edilen sorular?';

            this.Helper.trackEvents.pageView({categoryId:result.data.category.id});

          } else {
            this.$router.push(result.data.redirect_url);
          }
          this.pageLoaded = true;
        })
        .catch(err => {
          this.pageLoaded = true;
          this.$toasted.global.errorToast({
            description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
          });
        });
    }
  },
  computed:{
    navBarData: function () {

      if(this.category.parent_id){

        return {
          navBarList: [
            {
              title: "Destek",
              path: "/destek"
            },
            {
              title: this.category.parent_title,
              path: "/destek/kategori/"+this.category.parent_slug
            },
            {
              title: this.category.title,
              path: "/destek/kategori/"+this.category.slug
            }
          ],
          buttonTitle: "Yardım Merkezine Dön",
          buttonPath: "/destek"
        }
      } else {
        return {
          navBarList: [
            {
              title: "Destek",
              path: "/destek"
            },
            {
              title: this.category.title,
              path: "/destek/kategori/"+this.category.slug
            }
          ],
          buttonTitle: "Destek Taleplerim",
          buttonPath: "/tickets"
        }
      }

    }
  },
  watch:{
    '$store.state.route.path': function (newValue, oldValue) {
      if(newValue && newValue !== oldValue){
        let pathArr = this.$store.state.route.fullPath.split('/');
        if(pathArr && pathArr[3]){
          this.slug =  pathArr[3];
          this.getTopics();
        }
      }
    }
  },
  created() {
    document.body.scrollTop = 0;
    let pathArr = this.$store.state.route.fullPath.split('/');
    if(pathArr && pathArr[3]){
      this.slug =  pathArr[3];
      this.getTopics();
    } else {
     this.$router.push('/destek')
    }

  }
};
</script>

<style scoped lang="scss">


  .other-category{
    margin-top: 30px;
    .main-title{
      font-size: 46px;
      font-weight: bold;
      color: #2d3640;
    }

    .main-sub-title{
      font-size: 24px;
      font-weight: normal;
      line-height: 1.42;
      color: #5e6b79;
      margin-top: 10px;
    }
    .sub-categories{
      margin-top: 50px;
      .sub-category{
        font-size: 18px;
        font-weight: normal;
        line-height: 1.67;

        margin-bottom: 15px;
        a{
          color: #6d7174;
          &:hover{
            color: #00a575;
            text-decoration: none!important;
          }
        }
      }
    }
  }


  .inner-headers {
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d3640;
  }
  .inner-header-description {
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #5e6b79;
  }

  .big-icon /deep/{
    width: 50px;
    height: 46px;
    path {
      fill: #fd4056;
    }
  }

  .support-icon /deep/{
    width: 24px;
    height: 24px;
    path {
      fill: #00a575;
    }
  }

  .support-icon-container {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .new-ticket-bar-container {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #00a575;
    padding: 20px 20px;
    max-width: 85%;
    align-self: center;
  }
  .new-ticket-bar-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    margin: 0px 20px;
    color: #ffffff;
  }
  .new-ticket-button {
    height: 44px;
    border-radius: 2px;
    border: solid 1px #ffffff;
    background-color: transparent;
    min-width: 190px;
    cursor: pointer;
    display:flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: transparent;
      text-decoration: none;
      span{
        text-decoration: none;
      }
    }
  }
  .new-ticket-button-text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }


.left-bar-header {
  margin-top: 42px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
}
.category-detail-item {
  margin-left: 37px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}
.bionluk-copyright-text {
  margin-top: 20px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8b95a1;
}

.selected-category-item {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2d3640;
  padding-right: 47px;
  &:hover{
    text-decoration: none;
    color: #2d3640;
  }
}
.category-item {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #8b95a1;
  padding-right: 50px;
  &:hover{
    text-decoration: none;
    color: #2d3640;
  }
}

.selected-category-row {
  border-color: #fd4056;
  border-style: solid;
  border-width: 0px;
  border-right-width: 3px;
}

.selectedCat {
  opacity: 1;
  max-height: 1000px;
}
.unselectedCat {
  transition: all 1.4s ease-out;
  opacity: 0;
  overflow-y: hidden;
  max-height: 0px;
}
.selectedCatDetail {
  font-weight: 500;
  color: #2d3640;
}
.unselectedCatDetail {
  font-weight: normal;
  color: #8b95a1;
}


.useful-content-title {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
  margin-top: 20px;
  cursor: pointer;
  &:hover{
    text-decoration: none;
  }
}

.useful-content-detail {
  font-size: 18px;
  line-height: 1.67;

  color: #6d7174;
  max-width: 250px;

  margin-top: 15px;
  &:hover {
    color: #00a575;
    text-decoration: none;
  }
  a{
    font-size: 18px;
    line-height: 1.67;


    color: #6d7174;
    max-width: 250px;

    &:hover {
      color: #00a575;
      text-decoration: none;
    }
  }
}

.useful-content-show-more {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #fd4056;

  cursor: pointer;
}

</style>
